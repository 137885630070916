<template>
    <div class="invite">
        <p v-if="success">
            Thank you, your message has been sent.
        </p>
        <div v-if="!success" role="form">
            <div class="form-group">
                <label>Name</label>
                <input v-model="name" type="text" class="form-control">
                <small v-if="nameError" class="text-danger">Please enter your name</small>
            </div>
            <div class="form-group">
                <label>Email</label>
                <input v-model="email" type="email" class="form-control">
                <small v-if="emailError" class="text-danger">Please enter your email</small>
            </div>
            <div class="form-group">
                <label>Message</label>
                <textarea v-model="message" class="form-control" rows="2"></textarea>
                <small v-if="messageError" class="text-danger">Please enter a message</small>
            </div>
            <div class="button-area m-t-lg">
                <div v-show="error">
                    <small>
                        <span class="text-danger">{{ errorMessage }}</span>
                    </small>
                </div>
                <button name="button" class="btn btn-contact" @click.prevent="postMessage()">Send</button>
                <public-spinner v-show="loading" :inline="true" ml="16"></public-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import PublicSpinner from '../sharedteamwebsite/public_spinner.vue';

export default {
  name: 'contact-form',
  components: { PublicSpinner },
  props: [
    'teamName',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later.',

      name: '',
      nameError: false,

      email: '',
      emailError: false,

      message: '',
      messageError: false,

      success: false,
    };
  },
  methods: {
    resetFieldErrors() {
      const vm = this;
      vm.nameError = false;
      vm.emailError = false;
      vm.messageError = false;
    },
    validateForm() {
      const vm = this;

      vm.resetFieldErrors();
      let message = 'Please enter ';
      let error = false;

      if (vm.name.length === 0) {
        message += 'name, ';
        error = true;
        vm.nameError = true;
      }
      if (vm.email.length === 0) {
        message += 'email, ';
        error = true;
        vm.emailError = true;
      }
      if (vm.message.length === 0) {
        message += 'message, ';
        error = true;
        vm.messageError = true;
      }

      if (error) {
        message = message.slice(0, -2);
        message += '.';
        vm.errorMessage = message;
        vm.error = true;
        return false;
      }
      vm.error = false;
      return true;
    },
    postMessage: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.validateForm()) {
        vm.loading = true;
        const params = {
          inquiry: {
            name: vm.name,
            email: vm.email,
            message: vm.message,
          },
        };
        const url = '/api/team/v1/inquiries';
        axios.post(url, params)
          .then((response) => {
            vm.success = true;
            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later';
            vm.error = true;
          });
      }
    }, 500),
  },
};
</script>
