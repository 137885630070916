<template>
    <div>
        <div role="form" :style="getInverseStyles()">
            <div :class="getClass()">
                <label>Enter your code:</label>
                <input
                        v-model="guess"
                        type="text"
                        ref="code-i"
                        class="form-control"
                        style="width: 50%"
                >
                <p v-show="error" class="text-danger" style="color: #FFF0F0;">
                    Try again, remember your code is case sensitive.
                </p><br/>
                <button v-on:click="checkCode" class="btn btn-success">Unlock</button>
            </div>
        </div>
        <!-- Sneaky hacker huh? You guessed right, if you can find this you can probably,
             figure out the code. If you want to register for the session early this badly, I will not stop you :) -->
        <div :style="getStyles()">
            <slot>
                test
            </slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'simple-lock',
  props: [
    'unlockCode',
  ],
  data() {
    return {
      unlocked: false,
      guess: '',
      error: false,
    };
  },
  methods: {
    getClass() {
      if (this.error) {
        return 'form-group has-error';
      }

      return 'form-group';
    },
    checkCode() {
      const vm = this;
      if (vm.unlockCode === vm.guess) {
        vm.unlocked = true;
        vm.error = false;
      } else {
        vm.error = true;
        vm.unlocked = false;
      }
    },
    getStyles() {
      if (this.unlocked) {
        return 'display: block;';
      }

      return 'display: none;';
    },
    getInverseStyles() {
      if (this.unlocked) {
        return 'display: none;';
      }

      return 'display: block;';
    },
  },
};
</script>
