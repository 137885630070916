<template>
    <div>

    </div>
</template>

<script>
export default {
  name: 'nav-builder',
  props: [
    'faqs',
  ],
  data() {
    return {

    };
  },
  mounted() {
    this.parseFaqs();
  },
  methods: {
    parseFaqs() {
      const vm = this;
      if (!vm.faqs) {
        return;
      }

      try {
        const html = JSON.parse(vm.faqs);
        const faq_dom = $($.parseHTML(html));
        const nav_links = [];
        faq_dom.find('.is-nav-link').each(function () {
          const target = `<li>
                                <a href="/faqs#${$(this).attr('name')}">${$(this).attr('data-title')}</a>
                            </li>`;
          nav_links.push(target);
        });

        const $faqNav = $('#faq-nav');
        if (nav_links.length > 0 && $faqNav) {
          const newNavHtml = `
                            <li role="presentation" class="dropdown" id="faq-nav">
                                <a id="faq-drop" href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                  ${$faqNav.text()}
                                  <span class="caret"></span>
                                </a>
                                <ul id="faq-menu" class="dropdown-menu" aria-labelledby="faq-drop">
                                    ${nav_links.join('')}
                                </ul>
                          </li>
                        `;
          $faqNav.replaceWith(newNavHtml);
          $('.dropdown-toggle').dropdown();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
