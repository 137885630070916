// This will eventually be the source for marketing js files
import Vue from 'vue/dist/vue.esm';
import axiosSetup from '../sharedteamwebsite/axiosSetup';
import './styles/teamwebsite.scss';
import '../sharedteamwebsite/styles/donations.scss';

import Rails from '@rails/ujs';

import 'bootstrap';
import 'fullcalendar';
import ApiService from '../wrestling/api_service.js';
import BootstrapCalendar from './bootstrap_calendar.vue';
import ContactForm from './contact_form.vue';
import DonorBox from '../sharedteamwebsite/donor_box.vue';
import DonorFreeEntry from './donor_free_entry.vue';
import PublicCalendar from '../sharedteamwebsite/public_calendar.vue';
import NavBuilder from './nav_builder.vue';
import SimpleLock from './simple_lock.vue';
import SwalAlert from '../shared/swal_alert.vue';

Rails.start();

global.$ = require('jquery');

import { initSentry } from '../shared/sentry_config';

// Initialize Sentry
initSentry(Vue);
$(document).ready(() => {
  $('.carousel').carousel({
    interval: 5000,
  });
});

$(document).on('click', '.navbar-collapse.in', function (e) {
  if ($(e.target).is('a') && ($(e.target).attr('class') !== 'dropdown-toggle')) {
    $(this).collapse('hide');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  axiosSetup();

  const api = new ApiService();

  Object.defineProperties(Vue.prototype, {
    $apiService: {
      get() {
        return api;
      },
    },
  });

  const app = new Vue({
    el: '#team-website-vue-wrapper',
    components: {
      BootstrapCalendar,
      PublicCalendar,
      ContactForm,
      DonorBox,
      DonorFreeEntry,
      NavBuilder,
      SimpleLock,
      SwalAlert,
    },
  });
});
