<template>
  <div id="donor-free-entry">
    <div class="text-center">
      <h1>
        Donate to<br/>{{ teamName }}
      </h1>
    </div>
    <div class="text-center m-t-m">
      <money ref="money" v-bind="money" v-bind:value="amount / 100"
             @input="amount = $event * 100"
             class="form-input text-center"></money>
    </div>
    <div class="form-group text-center m-t-xs">
      <label>
        <input type="checkbox" v-model="monthly"> Make this a monthly donation
      </label>
    </div>
    <div class="footer">
      <recaptcha
          ref="recaptcha"
          :captcha-action="captchaAction"
          :captcha-site-key="captchaSiteKey"
          :captcha-v3-site-key="captchaV3SiteKey"
          style="margin-bottom: 16px;"
      ></recaptcha>
      <button type="button"
              class="btn btn-primary btn-block" @click="createCheckoutSession" :style="stylesForCta()">
        {{ btnCta }}
        <public-spinner v-show="loading" :inline="true" ml="16"></public-spinner>
      </button>
      <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
      <div id="donor-box-accessory">
        <div class="lock-icon">
          <svg viewBox="0 0 20 20" fill="currentColor" class="icon">
            <path fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <p id="accessory-text">
          You will be redirected to Stripe for secure payment processing. <a target="_blank"
                                                                             href="https://www.wrestlingiq.com?ref=donor-free-entry">Powered
          by WrestlingIQ</a>.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { Money } from 'v-money';
import PublicSpinner from '../sharedteamwebsite/public_spinner.vue';
import { stripeMix } from '../shared/stripe_mix.js';
import { recaptchaMix } from '../shared/recaptcha_mix.js';
import Recaptcha from '../shared/recaptcha.vue';

export default {
  name: 'donor-free-entry',
  mixins: [
    stripeMix,
    recaptchaMix,
  ],
  components: {
    PublicSpinner,
    Money,
    Recaptcha,
  },
  props: {
    teamName: {
      type: String,
      required: true,
    },
    apiKey: {
      type: String,
    },
    successUrl: {
      type: String,
    },
    primaryColor: {
      type: String,
    },
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      amount: 0,
      monthly: false,
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
    };
  },
  computed: {
    btnCta() {
      const amount = this.amount / 100;
      const recurringExtra = this.monthly ? '/month' : '';
      return `Donate $${amount} ${recurringExtra}`;
    },
  },
  mounted() {
    this.setupStripe();
    this.$nextTick(function () {
      this.$refs.money.$el.focus();
    });
  },
  methods: {
    stylesForCta() {
      if (this.primaryColor) {
        return `background-color: ${this.primaryColor}; border-color: ${this.primaryColor}`;
      }

      return '';
    },
    createCheckoutSession: _.throttle(async function () {
      const vm = this;
      if (vm.loading) {
        return;
      }
      vm.error = false;

      if (vm.amount <= 0) {
        vm.errorMessage = 'Please enter an amount';
        vm.error = true;
        return;
      }

      vm.loading = true;
      // Okay so this is a stripe checkout session specific to donations
      // Because this is manual entry, we pass in -1 to price to indicate
      // we have no predefined plan / tier for this
      const url = '/api/team/v1/donations/checkouts';
      const data = {
        'g-recaptcha-response-data': await vm.$refs.recaptcha.getCaptchaResponse(),
        success_url: vm.successUrl ? vm.successUrl : `${window.location.href}/donate/success`,
        cancel_url: window.location.href,
        account_id: vm.stripeAcctId,
        price: {
          id: -1,
          amount: vm.amount,
          interval: vm.monthly ? 'monthly' : '',
        },
      };
      axios.post(url, data)
          .then((response) => {
            vm.loading = false;
            vm.stripe.redirectToCheckout({
              sessionId: response.data.id,
            })
                .then((result) => {
                  vm.errorMessage = result.error.message;
                  vm.error = true;
                });
          })
          .catch((error) => {
            vm.loading = false;
            if (vm.isRecaptchaError(error)) {
              vm.$refs.recaptcha.handleCaptchaFailed();
              vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
            } else {
              vm.errorMessage = `Error, please try again later ${error.toString()}`;
            }
            vm.error = true;
          });
    }, 500),

  },
};
</script>
