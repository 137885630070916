import qs from 'qs'
export default class ApiService {
  constructor() {
    this.prefix = '/api/v1';
  }

  loadAllPages(url, key, data = []) {
    const vm = this;
    const getPage = function (url) {
      return axios.get(url)
        .then((response) => {
          data.push.apply(data, response.data[key]);
          const nextPageUrl = vm.nextPageUrl(response.headers);
          if (nextPageUrl) {
            return getPage(nextPageUrl);
          }
          return data;
        })
        .catch((error) => {
          throw error;
        });
    };
    return getPage(url);
  }

  nextPageUrl(headers) {
    const vm = this;
    if (!headers || !headers.link) {
      return null;
    }

    const paginationLinks = headers.link.split(',');
    let nextPageUrl = null;

    paginationLinks.forEach((l) => {
      const nextRel = 'rel="next"';
      if (l.indexOf(nextRel) !== -1) {
        const regExp = /\<([^>]+)>/;
        nextPageUrl = regExp.exec(l)[1];
      }
    });

    return nextPageUrl;
  }

  // Urls
  accountsUrl() {
    return `${this.prefix}/accounts`;
  }

  // Metrics
  grossVolume(range, intervalGroup) {
    return `${this.prefix}/metrics/gross_volume?interval_group=${intervalGroup}&range=${range}`;
  }

  mrr(range, intervalGroup) {
    return `${this.prefix}/metrics/mrr?interval_group=${intervalGroup}&range=${range}`;
  }
  newSubscribers(range, intervalGroup) {
    return `${this.prefix}/metrics/new_subscribers?interval_group=${intervalGroup}&range=${range}`;
  }

  revPerSubscriber(range, intervalGroup) {
    return `${this.prefix}/metrics/revenue_per_subscriber?interval_group=${intervalGroup}&range=${range}`;
  }

  chargeCount(range, intervalGroup) {
    return `${this.prefix}/metrics/charge_count?interval_group=${intervalGroup}&range=${range}`;
  }

  chargeAvg(range, intervalGroup) {
    return `${this.prefix}/metrics/charge_avg?interval_group=${intervalGroup}&range=${range}`;
  }
  activeSubscribers(range, intervalGroup) {
    return `${this.prefix}/metrics/active_subscribers?interval_group=${intervalGroup}&range=${range}`;
  }

  netVolume(range, intervalGroup) {
    return `${this.prefix}/metrics/net_volume?interval_group=${intervalGroup}&range=${range}`;
  }
  accountSwitchUrl() {
    return `${this.prefix}/accounts/switch`;
  }

  billingProfileUrl(billingProfileId) {
    return `${this.prefix}/billing_profiles/${billingProfileId}`;
  }

  coachUrl(coachId) {
    return `${this.prefix}/coaches/${coachId}`;
  }

  coachesUrl() {
    return `${this.prefix}/coaches`;
  }

  couponsUrl() {
    return `${this.prefix}/coupons`;
  }

  couponUrl(couponId) {
    return `${this.prefix}/coupons/${couponId}`;
  }

  checkInsUrl(eventId) {
    return `${this.prefix}/events/${eventId}/check_ins`;
  }

  customersUrl() {
    return `${this.prefix}/customers`;
  }

  eventTeamScoresUrl(eventId) {
    return `${this.prefix}/events/${eventId}/team_scores`;
  }

  eventCheckInUrl(eventId, checkInId) {
    return `${this.prefix}/events/${eventId}/check_ins/${checkInId}`;
  }

  eventMatchInvitesUrl(eventId) {
    return `${this.prefix}/events/${eventId}/match_invites`;
  }

  eventWrestlersUrl(eventId) {
    return `${this.prefix}/events/${eventId}/wrestlers`;
  }

  createDocumentUrl() {
    return `${this.prefix}/documents`;
  }

  updateDocumentUrl(docId) {
    return `${this.prefix}/documents/${docId}`;
  }

  documentUrl(docId) {
    return `${this.prefix}/documents/${docId}`;
  }

  signAgainDocumentUrl(docId) {
    return `${this.prefix}/documents/${docId}/sign_again`;
  }

  teamScoreUrl(teamScoreId) {
    return `${this.prefix}/team_scores/${teamScoreId}`;
  }

  wrestlerPendingAgreementsUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/agreements?status=pending`;
  }

  parentPendingAgreementsUrl(parentId) {
    return `${this.prefix}/parents/${parentId}/agreements?status=pending`;
  }

  coachPendingAgreementsUrl(coachId) {
    return `${this.prefix}/coaches/${coachId}/agreements?status=pending`;
  }

  paymentPlanPreviewUrl() {
    return `${this.prefix}/payment_plans/preview`;
  }

  paymentPlansUrl() {
    return `${this.prefix}/payment_plans`;
  }

  paymentPlanUrl(planId) {
    return `${this.prefix}/payment_plans/${planId}`;
  }

  membershipsUrl() {
    return `${this.prefix}/memberships`;
  }

  mergeJobsUrl() {
    return `${this.prefix}/merge_jobs`;
  }

  previewMembershipUrl() {
    return `${this.prefix}/memberships/preview`;
  }

  printJobsUrl() {
    return `${this.prefix}/print_jobs`;
  }

  manualInvoiceUrl(invoiceId) {
    return `${this.prefix}/manual_invoices/${invoiceId}`;
  }

  manualInvoicesUrl() {
    return `${this.prefix}/manual_invoices`;
  }

  messagesUrl(groupId) {
    return `${this.prefix}/message_groups/${groupId}/messages`;
  }

  messageMembershipsUrl(groupId) {
    return `${this.prefix}/message_groups/${groupId}/memberships`;
  }

  messageMembershipUrl(membershipId) {
    return `${this.prefix}/message_memberships/${membershipId}`;
  }

  notificationPreferencesUrl() {
    return `${this.prefix}/notification_preferences`;
  }

  notificationPreferenceUrl(prefId) {
    return `${this.prefix}/notification_preferences/${prefId}`;
  }

  onlineStoresUrl() {
    return `${this.prefix}/online_stores`;
  }

  onlineStoreUrl(storeId) {
    return `${this.prefix}/online_stores/${storeId}`;
  }

  userUrl(userId) {
    return `${this.prefix}/users/${userId}`;
  }

  readMessagesUrl(groupId) {
    return `${this.prefix}/message_groups/${groupId}/read`;
  }

  messageGroupsUrl() {
    return `${this.prefix}/message_groups`;
  }

  messageGroupUrl(groupId) {
    return `${this.prefix}/message_groups/${groupId}`;
  }

  guardianWrestlersUrl(guardianType, guardianId) {
    return `${this.prefix}/guardians/${guardianType}/${guardianId}/wrestlers`;
  }

  wrestlerGuardiansUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/guardians`;
  }

  guardianRelationshipUrl() {
    return `${this.prefix}/guardian_relationships`;
  }

  kioskTokensUrl() {
    return `${this.prefix}/kiosk_tokens`;
  }

  parentsUrl() {
    return `${this.prefix}/parents`;
  }

  parentUrl(pId) {
    return `${this.prefix}/parents/${pId}`;
  }

  profileEventSubscriptionsUrl() {
    return `${this.prefix}/profile_event_subscriptions`;
  }

  profileEventSubscriptionUrl(id) {
    return `${this.prefix}/profile_event_subscriptions/${id}`;
  }

  productsUrl() {
    return `${this.prefix}/products`;
  }

  productUrl(productId) {
    return `${this.prefix}/products/${productId}`;
  }

  registrationQuestions(profileType, sessionParam) {
    let url = null;

    if (profileType === 'ParentProfile') {
      url = `${this.prefix}/registration_questions?for_type=parents`;
    } else if (profileType === 'WrestlerProfile') {
      url = `${this.prefix}/registration_questions?for_type=wrestlers`;
    } else if (profileType === 'CoachProfile') {
      url = `${this.prefix}/registration_questions?for_type=coaches`;
    } else if (profileType === 'CheckIn') {
      url = `${this.prefix}/registration_questions?for_type=check_ins`;
    }

    if (url && sessionParam) {
      url = `${url}&paid_session=${sessionParam}`;
    }

    return url;
  }

  upsellsUrl() {
    return `${this.prefix}/upsells`;
  }

  upsellUrl(upsellId) {
    return `${this.prefix}/upsells/${upsellId}`;
  }

  wrestlerPropertiesForReports() {
    return `${this.prefix}/registration_questions?for_type=wrestlers&paid_session=properties&for_reports=true`;
  }

  privateWrestlerPropertiesForReports() {
    return `${this.prefix}/registration_questions?for_type=wrestlers&visibility=private&for_reports=true`;
  }

  privateWrestlerProperties() {
    return `${this.prefix}/registration_questions?for_type=wrestlers&visibility=private`;
  }

  privateWrestlerPropertyAnswers(wrestlerId) {
    return `${this.prefix}/registration_answers?profile_id=${wrestlerId}&profile_type=wrestlerprofile&visibility=private`;
  }

  registrationAnswers(profileId, profileType, checkInId, sessionParam) {
    let url = `${this.prefix}/registration_answers?profile_id=${profileId}&profile_type=${profileType}`;
    if (checkInId) {
      url = `${url}&check_in_id=${checkInId}`;
    }
    if (sessionParam) {
      url = `${url}&paid_session_id=${sessionParam}`;
    }

    return url;
  }

  signatureUrl() {
    return `${this.prefix}/signatures`;
  }

  searchUrl() {
    return `${this.prefix}/search`;
  }

  guardianExactSearchUrl(firstName, lastName) {
    return `${this.prefix}/search?search_type=guardian_exact_match&first_name=${firstName}&last_name=${lastName}`;
  }

  attachmentsUrl() {
    return `${this.prefix}/attachments`;
  }

  s3SignatureUrl() {
    return `${this.prefix}/attachments/s3_signature`;
  }

  s3ConfirmUrl() {
    return `${this.prefix}/attachments/s3_confirm`;
  }

  eventNotifyUrl(eventId) {
    return `${this.prefix}/events/${eventId}/notify`;
  }

  eventsUrl(params) {
    return `${this.prefix}/events${params}`;
  }

  eventUrl(eventId) {
    return `${this.prefix}/events/${eventId}`;
  }

  deleteEventRecurringUrl(eventId) {
    return `${this.prefix}/events/${eventId}?delete_recurring=true`;
  }

  eventInvitesUrl(eventId) {
    return `${this.prefix}/events/${eventId}/event_invites`;
  }

  eventInviteBatchUrl(eventId) {
    return `${this.prefix}/events/${eventId}/event_invites/batch`;
  }

  eventInviteUrl(eventInviteId) {
    return `${this.prefix}/event_invites/${eventInviteId}`;
  }

  invitesUrl() {
    return `${this.prefix}/invites`;
  }

  tagsUrl() {
    return `${this.prefix}/tags`;
  }
  inviteUrl(token) {
    return `${this.prefix}/invites/${token}`;
  }
  resendInviteUrl(token) {
    return `${this.prefix}/invites/${token}/resend`;
  }

  matchUrl(matchId) {
    return `${this.prefix}/matches/${matchId}`;
  }

  matchesUrl() {
    return `${this.prefix}/matches`;
  }

  matchScoreEvents(matchId) {
    return `${this.prefix}/matches/${matchId}/scoring_events`;
  }

  rulesetUrl(name) {
    return `${this.prefix}/scoring_rules/${name}`;
  }

  pairingCriteriaUrl() {
    return `${this.prefix}/pairing_criteria`;
  }

  eventMatchesUrl(eventId) {
    return `${this.prefix}/events/${eventId}/matches`;
  }

  funnelsUrl() {
    return `${this.prefix}/funnels`;
  }

  fundraiserUrl(fundraiserId) {
    return `${this.prefix}/fundraisers/${fundraiserId}`;
  }

  // Get the billing profile for a given profile
  profileBillingProfileUrl(profileId, profileType) {
    return `${this.prefix}/billing_profiles/${profileType}/${profileId}?for_update=true`;
  }

  fundraisersUrl() {
    return `${this.prefix}/fundraisers`;
  }

  fundraiserPricesUrl(fundraiserId) {
    return `${this.prefix}/fundraisers/${fundraiserId}/billing_plans`;
  }

  billingPlans(params) {
    return `${this.prefix}/billing_plans?${qs.stringify(params, { arrayFormat: 'brackets' })}`;
  }

  matchFeedbacksUrl(matchId) {
    return `${this.prefix}/matches/${matchId}/feedbacks`;
  }

  matchNotesUrl(matchId) {
    return `${this.prefix}/matches/${matchId}/notes`;
  }

  updateNoteUrl(noteId) {
    return `${this.prefix}/notes/${noteId}`;
  }

  opposingTeamsUrl() {
    return `${this.prefix}/opposing_teams`;
  }

  opposingTeamUrl(opposingTeamId) {
    return `${this.prefix}/opposing_teams/${opposingTeamId}`;
  }

  opposingWrestlersUrl() {
    return `${this.prefix}/opposing_wrestlers`;
  }

  originalVideoUrl(originalVideoId) {
    return `${this.prefix}/original_videos/${originalVideoId}`;
  }

  originalVideosUrl() {
    return `${this.prefix}/original_videos`;
  }

  futureSessionsUrl() {
    return `${this.prefix}/paid_sessions?type=ends_in_future`;
  }

  recurringSessionsUrl() {
    return `${this.prefix}/paid_sessions?type=recurring`;
  }

  // todo clean this up
  registerableSessionsUrl(forGuests, recurringSessions) {
    let filter = 'registerable';
    if (forGuests) {
      filter = 'guest_registerable';
    }
    if (recurringSessions) {
      filter = 'recurring';
    }
    return `${this.prefix}/paid_sessions?type=${filter}`;
  }

  paidSessionsUrl() {
    return `${this.prefix}/paid_sessions`;
  }

  paidSessionUrl(sessionId) {
    return `${this.prefix}/paid_sessions/${sessionId}`;
  }

  paymentOptionsUrl() {
    return `${this.prefix}/payment_options`;
  }

  paymentOptionUrl(optionId) {
    return `${this.prefix}/payment_options/${optionId}`;
  }

  paidSessionBillingPlansUrl(paidSessionId) {
    return `${this.prefix}/paid_sessions/${paidSessionId}/billing_plans`;
  }

  billingPlanUrl(planId) {
    return `${this.prefix}/billing_plans/${planId}`;
  }

  practicePlansUrl() {
    return `${this.prefix}/practice_plans`;
  }

  practicePlanUrl(planId) {
    return `${this.prefix}/practice_plans/${planId}`;
  }

  practicePlanBlockCategoriesUrl() {
    return `${this.prefix}/practice_plan_block_categories`;
  }

  practicePlanBlocksUrl(planId) {
    return `${this.prefix}/practice_plans/${planId}/blocks`;
  }

  practicePlanBlockUrl(planId, blockId) {
    return `${this.prefix}/practice_plans/${planId}/blocks/${blockId}`;
  }

  practicePlanNotifyUrl(planId) {
    return `${this.prefix}/practice_plans/${planId}/notify`;
  }

  refundUrl() {
    return `${this.prefix}/refunds`;
  }

  registrationAnswersUrl() {
    return `${this.prefix}/registration_answers/batch`;
  }

  reportsUrl() {
    return `${this.prefix}/reports`;
  }

  reportUrl(reportId) {
    return `${this.prefix}/reports/${reportId}`;
  }

  teamUrl(teamId) {
    return `${this.prefix}/teams/${teamId}`;
  }

  templateBlocksUrl() {
    return `${this.prefix}/practice_plan_blocks`;
  }

  templateBlockUrl(templateId) {
    return `${this.prefix}/practice_plan_blocks/${templateId}`;
  }

  opponentScoutingAnswersUrl(opponentId) {
    return `${this.prefix}/opposing_wrestlers/${opponentId}/scouting_answers`;
  }

  opponentScoutingAnswerUrl(opponentId, scoutingAnswerId) {
    return `${this.prefix}/opposing_wrestlers/${opponentId}/scouting_answers/${scoutingAnswerId}`;
  }

  scoutingAnswerUrl(scoutingAnswerId) {
    return `${this.prefix}/scouting_answers/${scoutingAnswerId}`;
  }

  scoutingQuestionsUrl() {
    return `${this.prefix}/scouting_questions`;
  }

  athleteIdUrl() {
    return `${this.prefix}/athlete_identifiers`;
  }

  usawMemberships(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/usaw_memberships`;
  }

  usawMembership(wrestlerId, usawId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/usaw_memberships/${usawId}`;
  }

  wrestlerUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}`;
  }

  expandedRostersWrestlerUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}?expand_rosters=true`;
  }

  expandedAnswersWrestlerUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}?expand_registration_answers=true`;
  }

  wrestlerRosterMembershipsUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/roster_memberships`;
  }

  wrestlersUrl() {
    return `${this.prefix}/wrestlers`;
  }

  wrestlerReactivateUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/reactivate`;
  }

  wrestlerCheckIns(wrestlerId, params) {
    return `${this.prefix}/wrestlers/${wrestlerId}/check_ins?${qs.stringify(params, { arrayFormat: 'brackets' })}`;
  }

  filteredWrestlers(includeGuests, rosterIds, sortBy, perPage) {
    let params = [];
    params.push("expand_rosters=true");

    if (includeGuests) {
      params.push("include_guests=true");
    }

    if (sortBy) {
      params.push(`order=${sortBy}`)
    }

    if (rosterIds.length > 0) {
      rosterIds.forEach((rId) => {
        params.push(`roster_ids[]=${rId}`)
      })
    }

    params.push(`per_page=${perPage}`);

    let queryString = params.length > 0 ? `?${params.join('&')}` : "";
    return `${this.prefix}/wrestlers${queryString}`;
  }

  wrestlerNotesUrl(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/notes`;
  }

  rostersUrl() {
    return `${this.prefix}/rosters`;
  }

  rosterUrl(rosterId) {
    return `${this.prefix}/rosters/${rosterId}`;
  }

  ransackRostersUrl(ransackParams) {
    return `${this.prefix}/rosters?${this.formatRansackParams(ransackParams)}`;
  }

  formatRansackParams(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  }

  rosterUrl(rosterId) {
    return `${this.prefix}/rosters/${rosterId}`;
  }

  rosterSyncUrl(rosterId) {
    return `${this.prefix}/rosters/${rosterId}/sync`;
  }

  rosterMembershipsUrl(rosterId) {
    return `${this.prefix}/rosters/${rosterId}/roster_memberships`;
  }

  rosterWrestlersUrl(rosterId) {
    return `${this.prefix}/rosters/${rosterId}/wrestler_profiles`;
  }

  registrationQuestionUrl(questionId) {
    return `${this.prefix}/registration_questions/${questionId}`;
  }

  syncGuestRegQuestionsUrl() {
    return `${this.prefix}/registration_questions/guest/sync_all`;
  }

  registrationQuestionsUrl() {
    return `${this.prefix}/registration_questions`;
  }

  subscriptionUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}`;
  }

  renewSubscriptionUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}/renew`;
  }

  subscriptionDeleteCouponUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}/coupon`;
  }

  subscriptionCouponUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}/coupon`;
  }

  subscriptionChangePlanUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}/plan/replace`;
  }

  subscriptionPausePaymentsUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}/pause`;
  }

  subscriptionResumePaymentsUrl(subscriptionId) {
    return `${this.prefix}/billing_subscriptions/${subscriptionId}/resume`;
  }

  wrestlerBillingSubscriptions(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/billing_subscriptions`;
  }

  wrestlerClassPass(wrestlerId, passId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/class_passes/${passId}`;
  }

  wrestlerClassPasses(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/class_passes`;
  }

  availableWrestlerClassPasses(wrestlerId) {
    return `${this.prefix}/wrestlers/${wrestlerId}/class_passes?status=available`;
  }

  guardianBillingSubscriptions(guardianType, guardianId) {
    if (guardianType === "CoachProfile") {
      return `${this.prefix}/coaches/${guardianId}/billing_subscriptions`;
    }

    return `${this.prefix}/parents/${guardianId}/billing_subscriptions`;
  }
}
