<template>
  <public-calendar :event-render-callback="eventRender">
  </public-calendar>
</template>
<script>
import PublicCalendar from '../sharedteamwebsite/public_calendar.vue';
import { calendarHelpers } from '../sharedteamwebsite/mixins/calendar_helpers';

export default {
  name: 'bootstrap-calendar',
  components: { PublicCalendar },
  mixins: [calendarHelpers],
  methods: {
    eventRender(eventObj, $el) {
      let content = '';

      const time = this.eventDayTime(eventObj);
      content = `${content}
                        <div class="row">
                            <div class="col-xs-1">
                                <i class="fa fa-clock-o"></i>
                            </div>
                            <div class="col-xs-8">
                                ${time}
                            </div>
                        </div>
                    `;

      if (eventObj.location) {
        content = `${content}
                        <div class="row m-t-md">
                            <div class="col-xs-1">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <div class="col-xs-9">
                                ${eventObj.location}
                            </div>
                        </div>
                        `;
      }

      if (eventObj.notes) {
        content = `${content}
                        <div class="row m-t-md">
                            <div class="col-xs-1">
                                <i class="fa fa-info"></i>
                            </div>
                            <div class="col-xs-10">
                                ${eventObj.notes}
                            </div>
                        </div>
                        `;
      }

      content = `<div class="container-fluid">${content}</div>`;

      $el.popover({
        html: true,
        title: `<div>
                  <div class="pull-left">
                      ${eventObj.title}
                  </div>
                  <div class="pull-right">
                      <button type="button" class="popover-close"><i class="fa fa-times"></i></button>
                  </div>
                  <div class="clearfix"></div>
              </div>`,
        content,
        trigger: 'click',
        placement: 'top',
        container: 'body',
        viewport: { selector: 'body', padding: 0 },
      }).on('shown.bs.popover', () => {
        const btn = $('.popover-close');
        btn.on('click', () => {
          // close popover manually
          btn.closest('.popover').popover('hide');
        });
      });
    },
  },
};
</script>
